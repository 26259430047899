/* eslint-disable global-require */

import { AUTH_LOGIN_SUCCESS } from '../constants';

// The top-level (parent) route
const routes = {
  path: '',
  // Keep in mind, routes are evaluated in order
  children: [
    {
      path: '',
      load: () => import(/* webpackChunkName: 'fragrances' */ './fragrances'),
    },
    {
      path: '/fragrances',
      children: [
        {
          path: '',
          load: () =>
            import(/* webpackChunkName: 'fragrances' */ './fragrances'),
        },
        {
          path: '/create',
          load: () =>
            import(
              /* webpackChunkName: 'createFragrance' */ './fragrances/create'
            ),
        },
        {
          path: '/:id',
          children: [
            {
              path: '',
              load: () =>
                import(
                  /* webpackChunkName: 'fragranceSingle' */ './fragrances/single'
                ),
            },
            {
              path: '/edit',
              load: () =>
                import(
                  /* webpackChunkName: 'createFragrance' */ './fragrances/edit'
                ),
            },
            {
              path: '/playground',
              load: () =>
                import(
                  /* webpackChunkName: 'createFragrance' */ './fragrances/playground'
                ),
            },
          ],
        },
      ],
    },
    {
      path: '/ingredients',
      children: [
        {
          path: '',
          load: () =>
            import(/* webpackChunkName: 'ingredients' */ './ingredients'),
        },
      ],
    },
    {
      path: '/accords',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'accords' */ './accords'),
        },
        {
          path: '/create',
          load: () =>
            import(/* webpackChunkName: 'blocks' */ './accords/create'),
        },
      ],
    },
    {
      path: '/rating',
      children: [
        {
          path: '',
          load: () => import(/* webpackChunkName: 'rating' */ './rating'),
        },
        {
          path: '/overview',
          load: () =>
            import(/* webpackChunkName: 'rating' */ './rating/overview'),
        },
        {
          path: '/result/:id',
          load: () =>
            import(/* webpackChunckName: 'rating' */ './rating/result'),
        },
        {
          path: '/:type/:id/:inputGroupIndex?',
          load: () =>
            import(/* webpackChunckName: 'rating' */ './rating/single'),
        },
      ],
    },
    {
      path: '/login',
      load: () => import(/* webpackChunkName: 'auth' */ './login'),
    },
    // Wildcard routes, e.g. { path: '(.*)', ... } (must go last)
    {
      path: '(.*)',
      load: () => import(/* webpackChunkName: 'not-found' */ './not-found'),
    },
  ],

  async action({ app, next, pathname, services, store }) {
    // Execute each child route until one of them return the result
    const route = await next();
    const { isAuthenticated, profile } = store.getState().auth;
    // Provide default values for title, description etc.
    route.title = `${route.title || 'Untitled Page'} - ${app.name}`;
    route.description = route.description || '';

    console.info('isAuthenticated', isAuthenticated);

    const setUser = async () => {
      try {
        const { value } = await services.users.get('me');
        store.dispatch({
          type: AUTH_LOGIN_SUCCESS,
          payload: value,
        });
      } catch (e) {
        if (route.protected) {
          route.redirect = '/login';
        }
        console.info(e);
      }
    };

    if (!isAuthenticated) await setUser();

    if (profile.role === 'userInputAnalyzer') {
      if (!pathname.includes('rating')) {
        route.redirect = '/rating';
      }
    }

    return route;
  },
};

// The error page is available by permanent url for development mode
if (__DEV__) {
  routes.children.unshift({
    path: '/error',
    action: require('./error').default,
  });
}

export default routes;
