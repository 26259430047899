import feathers from '@feathersjs/client';
import feathersRest from '@feathersjs/rest-client';
import reduxifyServices from 'feathers-redux';

export const services = [
  'users',
  'fragrances',
  'accords',
  'orders',
  'input-groups',
  'input-group-types',
  'input-response-groups',
  'input-responses',
  'input-group-layouts',
  'stores',
  'store-accords',
];

export default (fetchClient, apiUrl) => {
  const feathersClient = feathers();
  const restClient = feathersRest(apiUrl);
  feathersClient.configure(restClient.fetch(fetchClient));
  services.forEach(service => {
    feathersClient.service(service);
  });

  // Configure Redux
  const rawServices = reduxifyServices(feathersClient, services);

  return rawServices;
};
