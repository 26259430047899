import {
  APP_DISMISS_SEARCH,
  APP_RECEIVE_SEARCH_INPUT,
  APP_RECEIVE_SEARCH_RESULT,
  APP_RECEIVE_USERS_RESULT,
  APP_SET_LOADED,
  AUTH_LOGOUT_SUCCESS,
  AUTH_VERIFY_USER_FAIL,
  AUTH_VERIFY_USER_REQUEST,
  AUTH_VERIFY_USER_SUCCESS,
} from '../constants';

const initialState = {
  loaded: false,
  loadMessage: 'Loading the application...',
  users: [],
  search: {
    active: false,
    loading: false,
    input: '',
    results: [],
  },
  error: {
    loading: false,
    logs: [],
  },
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case APP_SET_LOADED:
      return {
        ...state,
        loaded: action.payload,
      };
    case AUTH_VERIFY_USER_REQUEST:
      return {
        ...state,
        loadMessage: 'Verifying you session...',
      };
    case AUTH_VERIFY_USER_SUCCESS:
    case AUTH_VERIFY_USER_FAIL:
      return {
        ...state,
        loaded: true,
      };
    case AUTH_LOGOUT_SUCCESS:
      return initialState;
    case APP_RECEIVE_SEARCH_INPUT:
      return {
        ...state,
        search: {
          ...state.search,
          loading: !!action.payload,
          active: !!action.payload,
          input: action.payload,
        },
      };
    case APP_DISMISS_SEARCH:
      return {
        ...state,
        search: {
          ...state.search,
          loading: false,
          active: false,
        },
      };
    case APP_RECEIVE_SEARCH_RESULT:
      return {
        ...state,
        search: {
          ...state.search,
          loading: false,
          results: action.payload,
        },
      };
    case APP_RECEIVE_USERS_RESULT:
      return {
        ...state,
        users: action.payload,
      };
    default:
      return state;
  }
}
