import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';

import app from './app.reducer';
import auth from './auth.reducer';
// import orders from './orders.reducer';
import blocks from './blocks.reducer';
import fragranceEditor from './fragranceEditor.reducer';
import ingredients from './ingredients.reducer';
import playground from './playground.reducer';
import runtime from './runtime.reducer';

export default services => {
  const reducers = {
    runtime,
    app,
    auth,
    fragranceEditor,
    ingredients,
    // orders,
    blocks,
    form: formReducer,
    playground,
  };
  Object.keys(services).forEach(service => {
    reducers[service] = services[service].reducer;
  });
  return combineReducers(reducers);
};
