import {
  FRAGRANCE_EDIT_CANCEL,
  FRAGRANCE_EDIT_REQUEST,
  FRAGRANCE_UPDATE_SUCCESS,
} from '../constants';

const initialState = {
  editing: null,
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case FRAGRANCE_EDIT_REQUEST:
      return {
        ...state,
        editing: action.payload.fragranceId.toString(),
      };
    case FRAGRANCE_EDIT_CANCEL:
    case FRAGRANCE_UPDATE_SUCCESS:
      return {
        ...state,
        editing: null,
      };
    default:
      return state;
  }
}
