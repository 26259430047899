/* eslint-disable prettier/prettier */
export const SET_RUNTIME_VARIABLE = 'SET_RUNTIME_VARIABLE';

export const APP_SET_LOADED = 'APP_SET_LOADED';
export const APP_RECEIVE_SEARCH_INPUT = 'APP_RECEIVE_SEARCH_INPUT';
export const APP_DISMISS_SEARCH = 'APP_DISMISS_SEARCH';
export const APP_RECEIVE_SEARCH_RESULT = 'APP_RECEIVE_SEARCH_RESULT';
export const APP_RECEIVE_USERS_RESULT = 'APP_RECEIVE_USERS_RESULT';

// Legacy
export const AUTH_VERIFY_USER_REQUEST = 'AUTH_VERIFY_USER_REQUEST';
export const AUTH_VERIFY_USER_SUCCESS = 'AUTH_VERIFY_USER_SUCCESS';
export const AUTH_VERIFY_USER_FAIL = 'AUTH_VERIFY_USER_FAIL';
export const AUTH_LOGIN_USER_REQUEST = 'AUTH_LOGIN_USER_REQUEST';
export const AUTH_LOGIN_USER_SUCCESS = 'AUTH_LOGIN_USER_SUCCESS';
export const AUTH_LOGIN_USER_FAIL = 'AUTH_LOGIN_USER_FAIL';
// End legacy

export const AUTH_LOGIN_REQUEST = 'AUTH_LOGIN_REQUEST';
export const AUTH_LOGIN_SUCCESS = 'AUTH_LOGIN_SUCCESS';
export const AUTH_LOGIN_FAIL = 'AUTH_LOGIN_FAIL';
export const AUTH_LOGOUT_REQUEST = 'AUTH_LOGOUT_REQUEST';
export const AUTH_LOGOUT_SUCCESS = 'AUTH_LOGOUT_SUCCESS';
export const AUTH_SET_STAGE = 'AUTH_SET_STAGE';
export const AUTH_FORGOT_PASSWORD_REQUEST = 'AUTH_FORGOT_PASSWORD_REQUEST';
export const AUTH_FORGOT_PASSWORD_SUCCESS = 'AUTH_FORGOT_PASSWORD_SUCCESS';
export const AUTH_FORGOT_PASSWORD_FAIL = 'AUTH_FORGOT_PASSWORD_FAIL';
export const AUTH_CONFIRM_PASSWORD_SUCCESS = 'AUTH_CONFIRM_PASSWORD_SUCCESS';

export const FRAGRANCE_INIT_CREATE = 'FRAGRANCE_INIT_CREATE';
export const FRAGRANCE_CREATE_REQUEST = 'FRAGRANCE_CREATE_REQUEST';
export const FRAGRANCE_CREATE_SUCCESS = 'FRAGRANCE_CREATE_SUCCESS';
export const FRAGRANCE_CREATE_FAIL = 'FRAGRANCE_CREATE_FAIL';
export const FRAGRANCE_UPDATE_REQUEST = 'FRAGRANCE_UPDATE_REQUEST';
export const FRAGRANCE_UPDATE_SUCCESS = 'FRAGRANCE_UPDATE_SUCCESS';
export const FRAGRANCE_UPDATE_FAIL = 'FRAGRANCE_UPDATE_FAIL';
export const FRAGRANCE_FETCH_ALL_REQUEST = 'FRAGRANCE_FETCH_ALL_REQUEST';
export const FRAGRANCE_FETCH_SORT_REQUEST = 'FRAGRANCE_FETCH_SORT_REQUEST';
export const FRAGRANCE_FETCH_FILTER_REQUEST = 'FRAGRANCE_FETCH_FILTER_REQUEST';
export const FRAGRANCE_RESET_FILTER_REQUEST = 'FRAGRANCE_RESET_FILTER_REQUEST';
export const FRAGRANCE_FETCH_ALL_SUCCESS = 'FRAGRANCE_FETCH_ALL_SUCCESS';
export const FRAGRANCE_FETCH_ALL_FAIL = 'FRAGRANCE_FETCH_ALL_FAIL';
export const FRAGRANCE_FETCH_SINGLE_REQUEST = 'FRAGRANCE_FETCH_SINGLE_REQUEST';
export const FRAGRANCE_FETCH_SINGLE_SUCCESS = 'FRAGRANCE_FETCH_SINGLE_SUCCESS';
export const FRAGRANCE_FETCH_SINGLE_FAIL = 'FRAGRANCE_FETCH_SINGLE_FAIL';
export const FRAGRANCE_DUPLICATE_REQUEST = 'FRAGRANCE_DUPLICATE_REQUEST';
export const FRAGRANCE_DUPLICATE_SUCCESS = 'FRAGRANCE_DUPLICATE_SUCCESS';
export const FRAGRANCE_DUPLICATE_FAIL = 'FRAGRANCE_DUPLICATE_FAIL';
export const FRAGRANCE_EDIT_REQUEST = 'FRAGRANCE_EDIT_REQUEST';
export const FRAGRANCE_EDIT_CANCEL = 'FRAGRANCE_EDIT_CANCEL';
export const FRAGRANCE_CREATE_CANCEL = 'FRAGRANCE_CREATE_CANCEL';
export const FRAGRANCE_INGREDIENT_TAGS_UPDATE =
  'FRAGRANCE_INGREDIENT_TAGS_UPDATE';
export const FRAGRANCE_DELETE_REQUEST = 'FRAGRANCE_DELETE_REQUEST';
export const FRAGRANCE_DELETE_SUCCESS = 'FRAGRANCE_DELETE_SUCCESS';
export const FRAGRANCE_DELETE_FAIL = 'FRAGRANCE_DELETE_FAIL';

export const BLOCK_FETCH_ALL_FAIL = 'BLOCK_FETCH_ALL_FAIL';
export const BLOCK_FETCH_ALL_REQUEST = 'BLOCK_FETCH_ALL_REQUEST';
export const BLOCK_FETCH_ALL_SUCCESS = 'BLOCK_FETCH_ALL_SUCCESS';
export const BLOCK_FETCH_SINGLE_FAIL = 'BLOCK_FETCH_SINGLE_FAIL';
export const BLOCK_FETCH_SINGLE_REQUEST = 'BLOCK_FETCH_SINGLE_REQUEST';
export const BLOCK_FETCH_SINGLE_SUCCESS = 'BLOCK_FETCH_SINGLE_SUCCESS';
export const BLOCK_ADD_TO_FRAGRANCE = 'BLOCK_ADD_TO_FRAGRANCE';

export const INGREDIENTS_CREATE_REQUEST = 'INGREDIENTS_CREATE_REQUEST';
export const INGREDIENTS_FETCH_ALL_FAIL = 'INGREDIENTS_FETCH_ALL_FAIL';
export const INGREDIENTS_FETCH_ALL_REQUEST = 'INGREDIENTS_FETCH_ALL_REQUEST';
export const INGREDIENTS_FETCH_ALL_SUCCESS = 'INGREDIENTS_FETCH_ALL_SUCCESS';
export const INGREDIENT_SELECT = 'INGREDIENT_SELECT';
export const INGREDIENT_DESELECT = 'INGREDIENT_DESELECT';

export const ORDER_CREATE_REQUEST = 'ORDER_CREATE_REQUEST';
export const ORDER_CREATE_SUCCESS = 'ORDER_CREATE_SUCCESS';
export const ORDER_CREATE_FAIL = 'ORDER_CREATE_FAIL';
export const ORDER_FETCH_ALL_REQUEST = 'ORDER_FETCH_ALL_REQUEST';
export const ORDER_FETCH_ALL_SUCCESS = 'ORDER_FETCH_ALL_SUCCESS';
export const ORDER_FETCH_ALL_FAIL = 'ORDER_FETCH_ALL_FAIL';
export const ORDER_FETCH_SINGLE_REQUEST = 'ORDER_FETCH_SINGLE_REQUEST';
export const ORDER_FETCH_SINGLE_SUCCESS = 'ORDER_FETCH_SINGLE_SUCCESS';
export const ORDER_FETCH_SINGLE_FAIL = 'ORDER_FETCH_SINGLE_FAIL';

export const REVIEW_FETCH_DESCRIPTORS_REQUEST =
  'REVIEW_FETCH_DESCRIPTORS_REQUEST';
export const REVIEW_FETCH_DESCRIPTORS_SUCCESS =
  'REVIEW_FETCH_DESCRIPTORS_SUCCESS';
export const REVIEW_FETCH_DESCRIPTORS_FAIL = 'REVIEW_FETCH_DESCRIPTORS_FAIL';
export const REVIEW_SUBMIT_REQUEST = 'REVIEW_SUBMIT_REQUEST';
export const REVIEW_SUBMIT_SUCCESS = 'REVIEW_SUBMIT_SUCCESS';
export const REVIEW_SUBMIT_FAIL = 'REVIEW_SUBMIT_FAIL';

export const PLAYGROUND_UPDATE_PARAMS_REQUEST =
  'PLAYGROUND_UPDATE_PARAMS_REQUEST';
export const PLAYGROUND_UPDATE_PARAMS_SUCCESS =
  'PLAYGROUND_UPDATE_PARAMS_SUCCESS';
export const PLAYGROUND_SET_SLIDER_PARAMS = 'PLAYGROUND_SET_SLIDER_PARAMS';
export const PLAYGROUND_UPDATE_PARAMS_FAIL = 'PLAYGROUND_UPDATE_PARAMS_FAIL';
export const PLAYGROUND_UPDATE_ACCORDS_SUCCESS =
  'PLAYGROUND_UPDATE_ACCORDS_SUCCESS';
export const PLAYGROUND_SET_SELECTED_PARAM = 'PLAYGROUND_SET_SELECTED_PARAM';
export const PLAYGROUND_SET_SEARCH_SPACE_PERCENTAGE =
  'PLAYGROUND_SET_SEARCH_SPACE_PERCENTAGE';
export const PLAYGROUND_SET_RATINGS = 'PLAYGROUND_SET_RATINGS';
export const PLAYGROUND_UPDATE_MINMAX_ACCORDS_SUCCESS =
  'PLAYGROUND_UPDATE_MINMAX_ACCORDS_SUCCESS';
export const PLAYGROUND_SET_PARAMETERS = 'PLAYGROUND_SET_PARAMETERS';
export const PLAYGROUND_SET_MODIFIED_ACCORDS =
  'PLAYGROUND_SET_MODIFIED_ACCORDS';
