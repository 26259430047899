/**
 * Creates a wrapper function around the HTML5 Fetch API that provides
 * default arguments to fetch(...) and is intended to reduce the amount
 * of boilerplate code in the application.
 * https://developer.mozilla.org/docs/Web/API/Fetch_API/Using_Fetch
 */
import history from './history';

function createFetch(fetch, { apiKey, apiUrl, cookie, storage }) {
  // NOTE: Tweak the default options to suite your application needs
  const defaults = {
    method: 'GET',
    mode: apiUrl ? 'cors' : 'same-origin',
    // credentials: apiUrl ? 'include' : 'same-origin',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      ...(cookie ? { Cookie: cookie } : null),
    },
  };

  let jwtToken = null;

  return async (fetchUrl, fetchOptions) => {
    // Add full URL to fetch('/api') calls
    const isApiRequest = fetchUrl.startsWith('/api');
    const url = isApiRequest
      ? `${apiUrl}${fetchUrl.replace('/api', '')}`
      : fetchUrl;

    if (!jwtToken) {
      try {
        const token = await storage.get('token');

        if (token) {
          jwtToken = token;
        }
      } catch (err) {
        console.info('Could not get the user!');
      }
    }

    // Merge the new fetch('/api', {OPTIONS}) with the default ones and
    // add Authorization to headers if present
    const options = {
      ...defaults,
      ...fetchOptions,
      headers: {
        ...defaults.headers,
        ...(isApiRequest && apiKey ? { 'x-api-key': apiKey } : {}),
        ...(isApiRequest && jwtToken
          ? { Authorization: `Bearer ${jwtToken}` }
          : {}),
        ...(fetchOptions && fetchOptions.headers),
      },
    };

    try {
      const response = await fetch(url, options);
      if (
        response.status === 401 &&
        isApiRequest &&
        typeof window !== 'undefined'
      ) {
        const isSafePath = ['/login'].includes(history.location.pathname);
        if (isSafePath) return Promise.reject(response);
        return history.push('/login');
      }
      return Promise.resolve(response);
    } catch (err) {
      return Promise.reject(err);
    }
  };
}

export default createFetch;
