import * as Sentry from '@sentry/browser';
import { applyMiddleware, compose, createStore } from 'redux';
import reduxPromiseMiddleware from 'redux-promise-middleware';
import createSentryMiddleware from 'redux-sentry-middleware';
import thunk from 'redux-thunk';

import rootReducer from '../reducers';
import createHelpers from './createHelpers';
import createLogger from './logger';
// import createJames from './logger/james';

export default function configureStore(
  initialState,
  { services, ...helpersConfig },
) {
  const helpers = createHelpers({ services, ...helpersConfig });

  const middleware = [thunk.withExtraArgument(helpers), reduxPromiseMiddleware];

  let enhancer;
  // if (process.env.BROWSER) {
  //   middleware.push(createJames());
  // }

  if (__DEV__) {
    middleware.push(createLogger());

    // https://github.com/zalmoxisus/redux-devtools-extension#redux-devtools-extension
    let devToolsExtension = f => f;
    /* eslint-disable no-underscore-dangle */
    if (process.env.BROWSER && window.__REDUX_DEVTOOLS_EXTENSION__) {
      devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__();
    }
    /* eslint-enable */

    enhancer = compose(applyMiddleware(...middleware), devToolsExtension);
  } else {
    middleware.push(createSentryMiddleware(Sentry, {}));
    enhancer = applyMiddleware(...middleware);
  }

  // See https://github.com/reactjs/redux/releases/tag/v3.1.0
  const store = createStore(rootReducer(services), initialState, enhancer);

  // Hot reload reducers (requires Webpack or Browserify HMR to be enabled)
  if (__DEV__ && module.hot) {
    module.hot.accept('../reducers', () =>
      // eslint-disable-next-line global-require
      store.replaceReducer(require('../reducers').default),
    );
  }

  return store;
}
