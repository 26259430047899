import * as Sentry from '@sentry/browser';
import StyleContext from 'isomorphic-style-loader/StyleContext';
import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';
import { Provider as ReduxProvider } from 'react-redux';

import ApplicationContext from './ApplicationContext';

const ContextType = {
  fetch: PropTypes.func.isRequired,
  pathname: PropTypes.string.isRequired,
  app: PropTypes.shape().isRequired,
  query: PropTypes.shape(),
  store: PropTypes.shape(),
  storeSubscription: PropTypes.shape(),
  auth: PropTypes.shape(),
  // Integrate Redux
  // http://redux.js.org/docs/basics/UsageWithReact.html
  ...ReduxProvider.childContextTypes,
};

class App extends PureComponent {
  static propTypes = {
    // Enables critical path CSS rendering
    // https://github.com/kriasoft/isomorphic-style-loader
    children: PropTypes.element.isRequired,
    context: PropTypes.shape(ContextType).isRequired,
    insertCss: PropTypes.func.isRequired,
  };

  componentDidCatch(error, errorInfo) {
    // eslint-disable-next-line react/no-unused-state
    this.setState({ error });
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      Sentry.captureException(error);
    });
  }

  render() {
    const { children, context, insertCss } = this.props;
    // NOTE: If you need to add or modify header, footer etc. of the app,
    // please do that inside the Layout component.
    return (
      <StyleContext.Provider value={{ insertCss }}>
        <ApplicationContext.Provider value={context}>
          <ReduxProvider store={context.store}>
            {React.Children.only(children)}
          </ReduxProvider>
        </ApplicationContext.Provider>
      </StyleContext.Provider>
    );
  }
}

export default App;
