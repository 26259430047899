import {
  BLOCK_ADD_TO_FRAGRANCE,
  BLOCK_FETCH_ALL_FAIL,
  BLOCK_FETCH_ALL_REQUEST,
  BLOCK_FETCH_ALL_SUCCESS,
  BLOCK_FETCH_SINGLE_FAIL,
  BLOCK_FETCH_SINGLE_REQUEST,
  BLOCK_FETCH_SINGLE_SUCCESS,
  FRAGRANCE_INIT_CREATE,
} from '../constants';

const initialState = {
  loaded: false,
  isFetching: false,
  blocks: [],
  error: {
    loading: false,
    logs: [],
  },
  current: [],
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case BLOCK_FETCH_ALL_REQUEST:
      return {
        ...state,
        loaded: !!state.blocks.length,
        isFetching: true,
      };
    case BLOCK_FETCH_ALL_SUCCESS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        blocks: action.payload,
      };
    case BLOCK_FETCH_ALL_FAIL:
      return {
        ...state,
        loaded: false,
        isFetching: false,
      };
    case BLOCK_FETCH_SINGLE_REQUEST:
      return {
        ...state,
        isFetching: true,
      };
    case BLOCK_FETCH_SINGLE_SUCCESS:
      return {
        ...state,
        isFetching: false,
      };
    case BLOCK_ADD_TO_FRAGRANCE:
      return {
        ...state,
        current: action.payload,
      };
    case BLOCK_FETCH_SINGLE_FAIL:
      return {
        ...state,
        isFetching: false,
      };
    case FRAGRANCE_INIT_CREATE:
      return {
        ...state,
        current: [],
      };
    default:
      return state;
  }
}
