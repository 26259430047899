import {
  PLAYGROUND_SET_MODIFIED_ACCORDS,
  PLAYGROUND_SET_PARAMETERS,
  PLAYGROUND_SET_RATINGS,
  PLAYGROUND_SET_SEARCH_SPACE_PERCENTAGE,
  PLAYGROUND_SET_SELECTED_PARAM,
  PLAYGROUND_SET_SLIDER_PARAMS,
  PLAYGROUND_UPDATE_MINMAX_ACCORDS_SUCCESS,
  PLAYGROUND_UPDATE_PARAMS_FAIL,
  PLAYGROUND_UPDATE_PARAMS_REQUEST,
  PLAYGROUND_UPDATE_PARAMS_SUCCESS,
} from '../constants';

const parameters = [
  {
    label: 'Sweet',
    options: ['Not at all', 'Very much'],
    name: 'sweetnonsweet',
    value: 0,
  },
  {
    label: 'Fresh',
    options: ['Not at all', 'Very much'],
    name: 'freshnofresh',
    value: 0,
  },
  {
    label: 'Femininity',
    options: ['Masculine', 'Feminine'],
    name: 'masculine',
    value: 0,
  },
  {
    label: 'Sensual',
    options: ['Not at all', 'Very much'],
    name: 'sensualnonsensual',
    value: 0,
  },
  {
    label: 'Loudness',
    options: ['Loud', 'Quiet'],
    name: 'loudquiet',
    value: 0,
  },
  { label: 'Day/Night', options: ['Day', 'Night'], name: 'nightday', value: 0 },
  {
    label: 'Sophisticated',
    options: ['Not at all', 'Very much'],
    name: 'sophisticated',
    value: 0,
  },
];
const initialState = {
  parameters,
  computedParameters: parameters,
  computedParametersfromA2p: [],
  sliderParameters: parameters,
  updatingParameters: false,
  selectedParameter: null,
  modifiedAccords: [],
  computedAccords: [],
  models: [],
  error: null,
  searchSpacePercentage: 100,
  searchspacePercentageBeforeComputing: 100,
  ratings: [],
  minmaxAccords: {},
};

export default function runtime(state = initialState, action) {
  switch (action.type) {
    case PLAYGROUND_UPDATE_PARAMS_REQUEST:
      return {
        ...state,
        updatingParameters: true,
      };
    case PLAYGROUND_SET_SLIDER_PARAMS:
      return {
        ...state,
        modifiedAccords: action.payload.modifiedAccords,
        computedAccords: action.payload.modifiedAccords,
        parameters: action.payload.parameters,
        computedParameters: action.payload.parameters,
        sliderParameters: action.payload.sliderParameters,
      };
    case PLAYGROUND_UPDATE_PARAMS_SUCCESS:
      return {
        ...state,
        parameters: action.payload.parameters,
        computedParameters: action.payload.parameters,
        computedParametersfromA2p: action.payload.parameters,
        models: action.payload.models,
        ratings: action.payload.models.map(model => ({
          name: model,
          rating: 0,
        })),
        updatingParameters: false,
      };
    case PLAYGROUND_UPDATE_PARAMS_FAIL:
      return {
        ...state,
        updatingParameters: false,
        error: action.payload,
      };
    case PLAYGROUND_SET_SELECTED_PARAM:
      return {
        ...state,
        selectedParameter: action.payload,
      };
    case PLAYGROUND_SET_SEARCH_SPACE_PERCENTAGE:
      return {
        ...state,
        searchSpacePercentage: action.payload,
      };
    case PLAYGROUND_SET_RATINGS:
      return {
        ...state,
        ratings: action.payload,
      };
    case PLAYGROUND_UPDATE_MINMAX_ACCORDS_SUCCESS:
      return {
        ...state,
        searchspacePercentageBeforeComputing: state.searchSpacePercentage,
        minmaxAccords: action.payload,
        updatingParameters: false,
      };
    case PLAYGROUND_SET_MODIFIED_ACCORDS:
      return {
        ...state,
        modifiedAccords: action.payload,
      };
    case PLAYGROUND_SET_PARAMETERS:
      return {
        ...state,
        parameters: action.payload,
      };
    default:
      return state;
  }
}
