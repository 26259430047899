import {
  BLOCK_ADD_TO_FRAGRANCE,
  FRAGRANCE_CREATE_CANCEL,
  FRAGRANCE_CREATE_SUCCESS,
  FRAGRANCE_EDIT_REQUEST,
  FRAGRANCE_INGREDIENT_TAGS_UPDATE,
  FRAGRANCE_INIT_CREATE,
  FRAGRANCE_UPDATE_SUCCESS,
  INGREDIENT_SELECT,
  INGREDIENTS_FETCH_ALL_REQUEST,
  INGREDIENTS_FETCH_ALL_SUCCESS,
} from '../constants';

const initialState = {
  loaded: false,
  isFetching: false,
  ingredients: [],
  selected: [],
  error: {
    loading: false,
    logs: [],
  },
};

export default function app(state = initialState, action) {
  switch (action.type) {
    case INGREDIENTS_FETCH_ALL_REQUEST:
      return {
        ...state,
        loaded: !!state.ingredients.length,
        isFetching: true,
      };
    case INGREDIENTS_FETCH_ALL_SUCCESS:
      return {
        ...state,
        loaded: true,
        isFetching: false,
        ingredients: action.payload,
      };
    case INGREDIENT_SELECT:
      return {
        ...state,
        selected: action.payload,
      };
    case FRAGRANCE_EDIT_REQUEST:
    case FRAGRANCE_INGREDIENT_TAGS_UPDATE:
      return {
        ...state,
        selected: action.payload.accords,
      };
    case FRAGRANCE_CREATE_SUCCESS:
      return {
        ...state,
        selected: [],
      };
    case FRAGRANCE_INIT_CREATE:
    case FRAGRANCE_UPDATE_SUCCESS:
    case FRAGRANCE_CREATE_CANCEL:
      return {
        ...state,
        selected: [],
      };
    case BLOCK_ADD_TO_FRAGRANCE:
      return {
        ...state,
        selected: [
          ...action.payload,
          ...state.selected.filter(({ ingredientId }) => {
            const res = action.payload.find(
              ({ ingredientId: aIngredientId }) =>
                ingredientId === aIngredientId,
            );
            return !res;
          }),
        ],
      };
    default:
      return state;
  }
}
