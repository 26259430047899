import Cookies from 'js-cookie';

import {
  AUTH_CONFIRM_PASSWORD_SUCCESS,
  AUTH_FORGOT_PASSWORD_FAIL,
  AUTH_FORGOT_PASSWORD_REQUEST,
  AUTH_FORGOT_PASSWORD_SUCCESS,
  AUTH_LOGIN_FAIL,
  AUTH_LOGIN_REQUEST,
  AUTH_LOGIN_SUCCESS,
  AUTH_LOGOUT_SUCCESS,
  AUTH_SET_STAGE,
} from '../constants';

const initialState = {
  stage: 'login',
  error: {
    message: null,
  },
  isSigningIn: false,
  isRequestingForgotPassword: false,
  isAuthenticated: !!Cookies.get('token'),
  profile: {},
};

export default function runtime(state = initialState, action) {
  switch (action.type) {
    case AUTH_LOGIN_REQUEST:
      return {
        ...state,
        isSigningIn: true,
        error: {
          message: null,
        },
      };
    case AUTH_LOGIN_SUCCESS:
      return {
        ...state,
        profile: action.payload || {},
        isAuthenticated: true,
        isSigningIn: false,
        error: {
          message: null,
        },
      };
    case AUTH_LOGIN_FAIL:
      return {
        ...state,
        isSigningIn: false,
        isAuthenticated: false,
        error: {
          ...state.error,
          message: action.payload,
        },
      };
    case AUTH_LOGOUT_SUCCESS:
      return {
        ...state,
        isAuthenticated: false,
      };
    case AUTH_SET_STAGE:
      return {
        ...state,
        stage: action.payload,
        error: {
          message: null,
        },
      };
    case AUTH_FORGOT_PASSWORD_REQUEST:
      return {
        ...state,
        isRequestingForgotPassword: true,
        error: {
          message: null,
        },
      };
    case AUTH_FORGOT_PASSWORD_SUCCESS:
      return {
        ...state,
        isRequestingForgotPassword: false,
        stage: 'confirmPassword',
        error: {
          message: null,
        },
      };
    case AUTH_FORGOT_PASSWORD_FAIL:
      return {
        ...state,
        isRequestingForgotPassword: false,
        error: {
          message: action.payload,
        },
      };
    case AUTH_CONFIRM_PASSWORD_SUCCESS:
      return {
        ...state,
        stage: 'confirmPasswordSuccess',
        isRequestingForgotPassword: false,
      };
    default:
      return state;
  }
}
