export default function createHelpers({
  auth,
  fetch,
  history,
  services,
  storage,
}) {
  return {
    auth,
    fetch,
    history,
    services,
    storage,
  };
}
